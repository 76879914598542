'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import React from 'react';
import s9 from 'style9';

import type { BiData } from '@haaretz/s-data-structure-types';
import type { BiFunction } from '@haaretz/s-use-bi';

interface GiftBtnProps {
  onClick?: () => void;
}

const c = s9.create({
  icon: {
    fontSize: space(5),
    verticalAlign: 'bottom',

    ...merge(
      mq({
        until: 's',
        value: {
          marginInlineEnd: space(2),
        },
      })
    ),
  },
  giftBtn: {
    ':hover': {
      '--mHTxtClr': color('neutral1100'),
    },
    ':focus': {
      '--mHTxtClr': color('neutral1100'),
    },
  },
  giftBtnMobile: {
    marginTop: space(2),
    marginBottom: space(8),
    width: 'fit-content',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'none',
        },
      })
    ),
  },
  giftBtnDesktop: {
    display: 'none',
    marginInlineEnd: space(-2),
    paddingInlineEnd: space(2),
    paddingInlineStart: space(2),
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'flex',
          marginInlineEnd: space(2),
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingInlineEnd: space(1),
          paddingInlineStart: space(1),
        },
      })
    ),
  },

  greetingTxt: {
    ...merge({
      ...mq({
        from: 's',
        value: {
          marginInlineStart: space(1),
          ...typesetter(-2),
        },
      }),
      ...mq({
        from: 'l',
        value: {
          ...typesetter(-1),
        },
      }),
      ...mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      }),
      ...mq({
        from: 'xxl',
        value: { ...typesetter(-3) },
      }),
    }),
  },

  giftIcon: {
    ...merge({
      ...mq({
        from: 's',
        value: {
          marginInlineEnd: space(1),
        },
      }),
    }),
  },
  giftName: {
    ...merge({
      ...mq({
        until: 'm',
        value: {
          display: 'none',
        },
      }),
    }),
  },
  giftNameLong: {
    ...merge({
      ...mq({
        until: 'l',
        value: {
          display: 'none',
        },
      }),
    }),
  },
  giftNameShort: {
    ...merge({
      ...mq({
        from: 'l',
        value: {
          display: 'none',
        },
      }),
    }),
  },
});

const biData: BiData = {
  campaign_name: 'HTZ gift subscription button',
  campaign_details: 'hello_default',
  feature: 'Hello Subscription',
  feature_type: 'Marketing',
  action_id: 49,
};

const onClickGiftBtn = (biAction: BiFunction) => {
  biAction(biData);
};

export function DesktopGiftBtn({ onClick }: GiftBtnProps) {
  const buttonRef = React.useRef<HTMLAnchorElement | null>(null);
  const biAction = useBi();
  const user = useUser('cookieValue');

  useImpressionObserver({
    elementRef: buttonRef,
    biData,
  });

  return fork({
    default: null,
    htz:
      user.userType === 'paying' ? (
        <Button
          href="https://promotion.haaretz.co.il/giftSubscription"
          as={HtzLink}
          variant="sales"
          priority="tertiary"
          onClick={() => {
            onClickGiftBtn(biAction);
            typeof onClick === 'function' && onClick();
          }}
          styleExtend={[c.giftBtn, c.giftBtnDesktop]}
          contentStyleExtend={[c.greetingTxt]}
          ref={buttonRef}
        >
          <Icon icon="gift" styleExtend={[c.icon, c.giftIcon]} />
          <span className={s9(c.giftName, c.giftNameLong)}>תנו מינוי במתנה</span>
          <span className={s9(c.giftName, c.giftNameShort)}>מינוי מתנה</span>
        </Button>
      ) : null,
  });
}

export function MobileGiftBtn({ onClick }: GiftBtnProps) {
  const buttonRef = React.useRef<HTMLAnchorElement | null>(null);
  const biAction = useBi();
  const user = useUser('cookieValue');

  useImpressionObserver({
    elementRef: buttonRef,
    biData,
  });

  return fork({
    default: null,
    htz:
      user.userType === 'paying' ? (
        <Button
          href="https://promotion.haaretz.co.il/giftSubscription"
          as={HtzLink}
          variant="sales"
          priority="primary"
          styleExtend={[c.giftBtn, c.giftBtnMobile]}
          onClick={() => {
            onClickGiftBtn(biAction);
            typeof onClick === 'function' && onClick();
          }}
          ref={buttonRef}
        >
          <Icon icon="gift" styleExtend={[c.icon]} />
          <span>תנו מינוי במתנה</span>
        </Button>
      ) : null,
  });
}
