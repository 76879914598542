'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import getUserSubscriptionDetails from '../../utils/getUserSubscriptionDetails';
import Hamburger from '../Hamburger';

// TODO: Convert this to a dynamic import after the Next.js bug is fixed
//       (https://github.com/vercel/next.js/issues/63918)
import DesktopNavigation from './DesktopNavigation';
// TODO: Convert this to a dynamic import after the Next.js bug is fixed
//       (https://github.com/vercel/next.js/issues/63918)
import MobileNavigation from './MobileNavigation';

import type { Variant } from '../../Masthead.types';
import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';
export interface NavigationMenuProps {
  mainNavigationItems: NavigationEntryFragment;
  variant: Variant;
  isOpen?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  focusOnFirstItem?: boolean;
}

// `c` is short for `classNames`
const c = s9.create({
  btn: {
    color: 'var(--mHTxtClr)',
    display: 'flex',
    gridRowStart: 1,
    zIndex: zIndex('above'),
    paddingInlineStart: space(2),
    paddingInlineEnd: space(2),
    ...typesetter(-1),

    ':hover': {
      '--mHTxtClr': color('neutral1100'),
    },
    ':focus': {
      '--mHTxtClr': color('neutral1100'),
    },

    ...merge(
      mq({
        until: 's',
        value: {
          height: '100%',
          marginInlineStart: space(-2),
        },
      }),
      mq({
        from: 's',
        value: {
          marginInlineStart: space(-4),
          marginInlineEnd: space(-1),
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingInlineEnd: space(1),
        },
      })
    ),
  },
  text: {
    ...merge(
      mq({
        until: 'm',
        value: {
          border: '0',
          clipPath: 'inset(50%)',
          height: '1px',
          margin: '0 -1px -1px 0',
          overflow: 'hidden',
          padding: '0',
          position: 'absolute',
          width: '1px',
          whiteSpace: 'nowrap',
          wordWrap: 'normal',
          opacity: 0,
        },
      }),
      mq({
        from: 's',
        value: {
          paddingInlineStart: space(7),
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  btnWhenOpen: {
    pointerEvents: 'none',
    backgroundColor: color('neutral200'),
  },
  isOpen: {
    color: color('primary1000'),
  },
  btnContent: {
    display: 'flex',
    alignItems: 'center',
  },
  li: {
    display: 'flex',
    width: space(60),
    height: space(10),
    paddingInlineStart: space(2),
    ...typesetter(-1),
    ...merge({
      ...mq({ from: 'xl', value: { width: space(78), height: space(9), ...typesetter(-2) } }),
    }),
    ':hover': {
      backgroundColor: color('neutral200'),
      color: color('primary1000'),
    },
  },
});

export default function NavigationMenuWrapper({
  mainNavigationItems,
  focusOnFirstItem,
  variant,
}: NavigationMenuProps) {
  // enable animation just after the first click, this boolean is for disable animation on the first render.
  const [enableAnimation, setEnableAnimation] = React.useState<boolean>(false);
  const platform = usePlatform();

  const biAction = useBi();
  const user = useUser('cookieValue');
  const [isOpen, setIsOpen] = React.useState(false);

  const { showPurchaseBtn, userProductType } = getUserSubscriptionDetails(user);

  const onClick = () => {
    setIsOpen(prev => !prev);
    isOpen
      ? biAction({
          action_id: 146,
          feature: 'close navigation',
          feature_type: 'Content',
        })
      : biAction({
          action_id: 132,
          feature: 'open navigation',
          feature_type: 'Content',
        });
  };

  const mainNavigation = mainNavigationItems?.subEntries?.filter(entry => {
    if (entry.kind === 'purchase') {
      return entry.includeUserTypes?.includes(userProductType) && showPurchaseBtn;
    }
    return entry.title && entry.href;
  });

  return (
    <React.Fragment>
      <Button
        // variant="neutral"
        priority="tertiary"
        styleExtend={[c.btn, isOpen && c.btnWhenOpen]}
        contentStyleExtend={[c.btnContent]}
        onClick={() => {
          setEnableAnimation(true);
          onClick();
        }}
        data-testid="main-navigation-btn"
      >
        {variant === 'extended' && platform === 'mobile' ? null : (
          <Hamburger
            isOpen={isOpen}
            enableMenuClosingAnimation={enableAnimation}
            location="navigationMenu"
            inlineStyle={
              isOpen
                ? {
                    '--mHTxtClr': color('primary1000'),
                  }
                : {}
            }
          />
        )}
        {mainNavigationItems?.title && (
          <span className={s9(c.text, isOpen && c.isOpen)}>{mainNavigationItems.title}</span>
        )}
      </Button>
      {platform === 'desktop' && (
        <DesktopNavigation mainNavigation={mainNavigation} onClick={onClick} isOpen={isOpen} />
      )}

      {platform === 'mobile' && (
        <MobileNavigation
          focusOnFirstItem={focusOnFirstItem}
          mainNavigation={mainNavigation}
          onClick={onClick}
          isOpen={isOpen}
        />
      )}
    </React.Fragment>
  );
}
