'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

export interface HamburgerProps {
  styleExtend?: StyleExtend;
  isOpen?: boolean;
  enableMenuClosingAnimation?: boolean;
  inlineStyle?: InlineStyles;
  location: 'navigationMenu' | 'bottomMobileNavigation';
  onAnimationEnd?: () => void;
}

const duration = '.4s';
const hamburgerWidth = `var(--mHamHWidth, ${space(3.2)})`;
const hamburgerHeight = `var(--mHamHeight, ${space(0.5)})`;
const hamburgerColor = `var(--mHTxtClr, ${color('neutral1100')})`;
const spacing = `var(--mHSpacing, ${space(1.2)})`;
const negativeSpacing = `calc(${spacing} * -1)`;

const c = s9.create({
  hamburger: {
    backgroundColor: hamburgerColor,
    borderRadius: space(6),
    display: 'block',
    fontWeight: 700,
    height: hamburgerHeight,
    marginBottom: '1px',
    marginInlineEnd: 'auto',
    marginInlineStart: 'auto',
    marginTop: '1px',
    position: 'absolute',
    width: hamburgerWidth,
    justifySelf: 'center',
    alignSelf: 'center',

    '::before': {
      backgroundColor: hamburgerColor,
      borderRadius: space(6),
      content: '""',
      height: hamburgerHeight,
      left: '0',
      position: 'absolute',
      width: hamburgerWidth,
    },
    '::after': {
      backgroundColor: hamburgerColor,
      borderRadius: space(6),
      content: '""',
      height: hamburgerHeight,
      left: '0',
      position: 'absolute',
      width: hamburgerWidth,
    },
  },
  default: {
    ':before': {
      top: negativeSpacing,
    },
    ':after': {
      top: spacing,
    },
  },
  closing: {
    backgroundColor: hamburgerColor,
    animationDuration: duration,
    animationName: s9.keyframes({
      '0%': { backgroundColor: 'transparent' },
      '100%': { backgroundColor: hamburgerColor },
    }),
    ':before': {
      left: '0',
      top: negativeSpacing,
      backgroundColor: hamburgerColor,
      animationDuration: duration,
      transform: 'translateY(0) rotate(0deg)',
      animationName: s9.keyframes({
        '0%': { transform: `translateY(${spacing}) rotate(-45deg)` },
        '50%': { transform: `translateY(${spacing}) rotate(0deg)` },
        '100%': { transform: 'translateY(0) rotate(0deg)' },
      }),
    },
    ':after': {
      top: spacing,
      backgroundColor: hamburgerColor,
      animationDuration: duration,
      transform: 'translateY(0) rotate(0deg)',
      animationName: s9.keyframes({
        '0%': { transform: `translateY(${negativeSpacing}) rotate(45deg)` },
        '50%': { transform: `translateY(${negativeSpacing}) rotate(0deg)` },
        '100%': { transform: 'translateY(0) rotate(0deg)' },
      }),
    },
  },
  opening: {
    backgroundColor: 'transparent',
    animationDuration: duration,
    animationName: s9.keyframes({
      '0%': { backgroundColor: color('primary1000') },
      '100%': { backgroundColor: 'transparent' },
    }),
    ':before': {
      backgroundColor: hamburgerColor,
      top: spacing,
      animationDuration: duration,
      transform: `translateY(${negativeSpacing}) rotate(-45deg)`,
      animationName: s9.keyframes({
        '0%': { transform: 'translateY(0) rotate(0deg)' },
        '50%': { transform: `translateY(${negativeSpacing}) rotate(0deg)` },
        '100%': { transform: `translateY(${negativeSpacing}) rotate(-45deg)` },
      }),
    },
    ':after': {
      backgroundColor: hamburgerColor,
      top: negativeSpacing,
      animationDuration: duration,
      transform: `translateY(${spacing}) rotate(45deg)`,
      animationName: s9.keyframes({
        '0%': { transform: 'translateY(0) rotate(0deg)' },
        '50%': { transform: `translateY(${spacing}) rotate(0deg)` },
        '100%': { transform: `translateY(${spacing}) rotate(45deg)` },
      }),
    },
  },
  hamburgerNavigationMenu: {
    ...merge(
      mq({ until: 'm', value: { marginInlineStart: space(-0.5) } }),
      mq({ from: 'm', value: { marginInlineStart: space(1.5) } })
    ),
  },
  sizePlaceholder: {
    opacity: 0,
    pointerEvents: 'none',

    ...merge(
      mq({
        from: 'm',
        value: { display: 'none' },
      })
    ),
  },
});

export default function Hamburger({
  isOpen,
  enableMenuClosingAnimation,
  inlineStyle,
  onAnimationEnd,
  location,
  styleExtend = [],
}: HamburgerProps) {
  return (
    <>
      <i
        className={s9(
          c.hamburger,
          isOpen ? c.opening : enableMenuClosingAnimation ? c.closing : c.default,
          location === 'navigationMenu' && c.hamburgerNavigationMenu,
          ...styleExtend
        )}
        style={inlineStyle}
        onAnimationEnd={onAnimationEnd}
      />
      <span aria-hidden="true" className={s9(c.sizePlaceholder)} style={inlineStyle}>
        o
      </span>
    </>
  );
}
